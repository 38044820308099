import React, {Component} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import userRights from '../../scripts/userRights';
import { getFromS3 } from '../../scripts/s3';
import EditQuestion from '../question/edit.view';
import editSessionScript from './edit.script';
import ViewQuestion from '../question/view.view';
import TestQuestion from '../question/test.view';
import logger from '../../helper/logger';
const CATEGORY=["IB","RF","MR","LR", "FI", "PR", "RV"];
import {batchWriteToDB} from '../../scripts/batchWriteToDB';
import {writeToDB} from '../../scripts/writeToDB';
export default class SessionDetails extends Component {
  
    constructor(props) {
        super(props);
        console.log(props.session)
        this.state={
            session:props.session,
            levelName: props.session.levelName,
            sessionNumber:props.session.sessionNumber,
            questionInView:[],
            org_questionInView:[],
            parentInView:[],
            order:0,
            view:false,
            edit:false,
            test:false,
            videoSaved:false
        }
    }
        
    componentDidMount = async() => {
        //get All questions for Ice breaker for this session
        let ib = this.state.session["IB"];
        this.setState({ib});
    }
    canSeeQuestion=(statusName)=>{
        if (userRights.isUserDesigner() && statusName === "FOR DESIGN")
            return true;
        if (userRights.isUserReviewer())
            return true;
    }
    canEditQuestion=(val) =>{
        return userRights.canEdit(val.attributes);
    }
    canDeleteQuestion=(val) =>{
        return userRights.canDelete(val.attributes);
    }
    testQuestion = async(val,idx)=>{
        await this.setQuestionDetails(val, idx);
        this.setTest(true);
    }
    viewQuestion=async(val, idx)=>
    {
        await this.setQuestionDetails(val, idx);
        this.setView(true);
    }
    setQuestionDetails = async(val,idx)=>{
        let questionInView =[];
        let parentInView=[];          
        let groupOfQuestions = await editSessionScript.fetchByGroupId(val.contentId);
        parentInView = groupOfQuestions.filter(parent=>parent.contentId === val.contentId);
        questionInView = groupOfQuestions.filter(question=> question.country_language === val.attributes.country_language);
        questionInView = questionInView[0];
        let org_questionInView = {...questionInView};
        parentInView = parentInView[0];
        let secondChild;
        if (questionInView.country_language === 'IN|EN')
         secondChild = groupOfQuestions.filter(question => question.country_language === 'US|EN')
        else
         secondChild = groupOfQuestions.filter(question => question.country_language === 'IN|EN')
        secondChild=secondChild[0];
        if (questionInView.qImage)
        {
          let image = await getFromS3(questionInView.qImage);
          questionInView.qImage = image;
        }
        if (questionInView.eImage)
        {
          let image = await getFromS3(questionInView.eImage);
          questionInView.eImage = image;
        }
        if (questionInView.oImages)
        {
          let images=[];
          for (var ii=0;ii<questionInView.oImages.length;ii++)
          {
            images[ii] = await getFromS3(questionInView.oImages[ii]);
            
          }
          questionInView.oImages = images;
        }      

        this.updateSessionQuestion(idx, questionInView, val.attributes.category, val.order);
        this.setState({questionInView,org_questionInView, parentInView,order:val.order, secondChild});
    }
    editQuestion=async(val, idx) => {
        console.log(val);
        let questionInView =[];
        let parentInView=[];
        let secondChild;          
        let groupOfQuestions = await editSessionScript.fetchByGroupId(val.contentId); 
        questionInView = groupOfQuestions.filter(question=> question.country_language === val.attributes.country_language);          
        console.log(questionInView);
        parentInView = groupOfQuestions.filter(parent=>parent.contentId === val.contentId);

/*         if (questionInView[0].copyQuestion === true)
        { */
            if (questionInView[0].country_language === 'IN|EN')
                secondChild = groupOfQuestions.filter(question => question.country_language === 'US|EN')
            else
                secondChild = groupOfQuestions.filter(question => question.country_language === 'IN|EN')
       /*  } */
        
        questionInView = questionInView[0];
        secondChild = secondChild[0];
        parentInView = parentInView[0];  
        let skillName = questionInView.skillName;
        this.updateSessionQuestion(idx, questionInView, val.attributes.category, val.order);
        this.setState({
            questionInView,
            parentInView,
            secondChild,
            skillName,
            edit:true
        }) 
    }
    updateSessionQuestion=(idx, question, category,order) => {
        let session = this.state.session;
        session[category][idx]={contentId: question.parentId, attributes: question, order:order}
        this.setState({session});
    }
    setView=(view)=>{
        this.setState({
          view
        })
    }
    setTest=(test)=>{
        this.setState({
          test
        })
    }
    setEdit=(edit)=>{
        this.setState({
          edit
        })
    }
    saveVideo=async(URL) => {
        let questionInView = this.state.org_questionInView;
        let parent = this.state.parentInView;
        let date = new Date();
        date = date.toISOString();
        questionInView["videoURL"] = URL;
        questionInView["updatedAt"] = date;
        parent["updatedAt"] = date;
        let secondChild = this.state.secondChild;
        let jsonObject=[];
        jsonObject.push(parent);
        jsonObject.push(questionInView);
        if (questionInView["copyQuestion"] === true)
        {
            secondChild["videoURL"] = URL;
            secondChild["updatedAt"] = date;            
            jsonObject.push(secondChild);
        }
        let res = await batchWriteToDB(jsonObject);

        if(Object.keys(res.UnprocessedItems).length)
        {
            logger.logMessage("Add Video","batchUpdateDB","There are a few UnProcessedItems"+res.UnprocessedItems.length);
            this.setState({videoSaved:false})
        }    
        else
        {
            logger.logMessage("Add Videos","batchUpdateDB","All items have been written successfully")
            this.setState({videoSaved:true})
        }
        //}
        // else
        // {
        //     let res = await writeToDB(questionInView);
        //     console.log(res);
        //     console.log("Updated video url for one question");
        //     this.setState({videoSaved:true})
        // }

    }
    render() 
    {      
        if (this.state.view){
            return (
                <ViewQuestion setView={this.setView} parent ={this.state.parentInView} question={this.state.questionInView}/>
            )
        }
        if (this.state.test){
            return (
                <TestQuestion saveVideo={this.saveVideo} videoSaved={this.state.videoSaved} order={this.state.order} show={this.state.test} setTest={this.setTest} parent ={this.state.parentInView} question={this.state.questionInView}/>
            )
        }
        if (this.state.edit)
        {
          return(<div>
                   <EditQuestion  setEdit={this.setEdit} 
                       question={this.state.questionInView} 
                       secondChild={this.state.secondChild}
                       parent ={this.state.parentInView}
                       isPublished ={this.state.isPublished}
                       canReviewQuestion={userRights.isUserReviewer()}
                       canDesignQuestion={userRights.isUserDesigner()}
                       skillName = {this.state.skillName}
                        />
                 </div>)
        } 
        return (
            <div class='container'>
                <Row><Col>
                <h4 style={{ textAlign: "center" }}>Session Details</h4>
                </Col></Row>
               <Row style={{align:"right"}}><Col ms={3} >
                    <Button className="float-right" onClick={this.props.goBack}>BACK</Button></Col>
                </Row><br></br>
                <br/>                
                <div>
                    <Row>                                    
                        <Col ms={12}>{`Level ${this.state.levelName} - Session ${this.state.sessionNumber}`}</Col>                              
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col><h5>Section 2: Information</h5></Col>
                    </Row>
                    <Row>                        
                        <Col ms={2} style={{color:"blue"}}>Ice Breaker</Col>
                        <Col ms={1}>{this.state.session["IB"] && this.state.session["IB"].length}</Col>
                        <Col ms={2} style={{color:"blue"}}>Rapid Fire</Col>
                        <Col ms={1}> {this.state.session["RF"] && this.state.session["RF"].length}</Col>
                        <Col ms={2} style={{color:"blue"}}>Math Reasoning</Col>
                        <Col ms={1}> {this.state.session["MR"] && this.state.session["MR"].length}</Col>
                        <Col ms={2} style={{color:"blue"}}>Logical Reasoning</Col>
                        <Col ms={1}> {this.state.session["LR"] && this.state.session["LR"].length}</Col>
                        <Col ms={2} style={{color:"blue"}}>Lessons</Col>
                        <Col ms={1}> {this.state.session["LN"] && this.state.session["LN"].length}</Col>  
                        <Col ms={2} style={{color:"blue"}}>Files</Col>
                        <Col ms={1}> {this.state.session["FI"] && this.state.session["FI"].length}</Col>  
                        <Col ms={2} style={{color:"blue"}}>Project</Col>
                        <Col ms={1}> {this.state.session["PR"] && this.state.session["PR"].length}</Col>  
                        <Col ms={2} style={{color:"blue"}}>Review</Col>
                        <Col ms={1}> {this.state.session["RV"] && this.state.session["RV"].length}</Col>                       
                    </Row>
                    <Row><Col ms={12}>
                        {CATEGORY.map((category, idx)=>{    
                            let oneRow=[];   
                                         
                            {(this.state.session[category]) && (category !== "FI") && (
                                oneRow.push(
                                <div>
                                    <Row>
                                        <Col ms={12}>{category} questions</Col>
                                    </Row>
                                    <Row><Col ms={12}>
                                    <div>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>                  
                                                    <th scope="col">Question</th>
                                                    <th scope="col">Country|Language</th>
                                                    <th scope="col">StatusName</th>
                                                    <th scope="col">Total Images</th>
                                                    <th scope="col">Test</th>
                                                    <th scope="col">Edit</th>
                                                    <th scope="col">Remove</th>                   
                                                </tr>
                                            </thead>
                                            
                                            {(this.state.session[category].map((val,idx) => {
                                                
                                                let rows=[];
                                                let attributes = val.attributes;
                                               if (this.canSeeQuestion(attributes.statusName))
                                                {                                                   
                                                    rows.push(
                                                        <tr>
                                                            <td>{val.order}</td>
                                                            <td  style={{cursor:"pointer", color:"blue", 
                                                                textDecoration: "underline"}} onClick={()=>this.viewQuestion(val,idx)}>{attributes.question}</td>
                                                            <td>{attributes.country_language}</td>
                                                            <td>{attributes.statusName}</td>
                                                            <td>{attributes.totalImages}</td>
                                                            {(userRights.isUserReviewer()) && (
                                                                <>
                                                                <td><Button variant="outline-success" onClick={()=>this.testQuestion(val,idx)} >TEST</Button></td>
                                                               {/*  <td><Button variant="outline-success" onClick={()=>this.editQuestion(val, idx)}>Edit</Button></td> */}
                                                                </>
                                                            )} 
                                                              {(this.canEditQuestion(val) && (attributes.copyQuestion === 'true' || attributes.copyQuestion === true) && (
                                                                <td><Button variant="outline-success" onClick={()=>this.editQuestion(val, idx)}>Edit</Button></td>
                                                            ))} 
                                                             {(this.canEditQuestion(val) && (attributes.copyQuestion === 'false' || attributes.copyQuestion === false) && (
                                                                <td>US and India versions are separate. Cannot edit from here</td>
                                                            ))}                                                             
                                                            {(this.canDeleteQuestion(val) && (
                                                                <td><Button variant="outline-warning" onClick={()=>this.removeQuestion(val, idx)}>Remove</Button></td>
                                                            ))}
                                                        </tr>
                                                    )
                                                }
                                                return <tbody>{rows}</tbody>
                                            }))
                                            }
                                        </table>                                        
                                    </div>  
                                    </Col></Row>
                                </div>
                            ))}
                            
                             {(this.state.session[category]) && (category === "FI") && (
                                oneRow.push(
                                    <div>
                                        <Row>
                                            <Col ms={12}>{category} Files</Col>
                                        </Row>
                                        <Row><Col ms={12}>
                                        <div>
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>                  
                                                        <th scope="col">File Name</th>
                                                        <th scope="col">Country|Language</th>
                                                        <th scope="col">StatusName</th>
                                                        <th scope="col">Remove</th>                   
                                                    </tr>
                                                </thead>
                                                
                                                {(this.state.session[category].map((val,idx) => {
                                                    debugger;
                                                    let rows=[];
                                                    let attributes = val.attributes;
                                                   if (this.canSeeQuestion(attributes.statusName))
                                                    {                                                   
                                                        rows.push(
                                                            <tr>
                                                                <td>{val.order}</td>
                                                                <td  style={{cursor:"pointer", color:"blue"}} >{attributes.fileName}</td>
                                                                <td>{attributes.country_language}</td>
                                                                <td>{attributes.statusName}</td>                                                                                                                          
                                                                {(this.canDeleteQuestion(val) && (
                                                                    <td><Button variant="outline-warning" onClick={()=>this.removeQuestion(val, idx)}>Remove</Button></td>
                                                                ))}
                                                            </tr>
                                                        )
                                                    }
                                                    return <tbody>{rows}</tbody>
                                                }))
                                                }
                                            </table>                                        
                                        </div>  
                                        </Col></Row>
                                    </div>
    
                            ))}
                            return <div>{oneRow}</div>
                        })}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}